import {
  Alert,
  Button,
  ScrollSpy,
  Tab,
} from 'bootstrap';

// Make available globally
window.Alert = Alert;
window.Button = Button;
window.ScrollSpy = ScrollSpy;
window.Tab = Tab;
